.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  max-width: 100%;
  pointer-events: none;
}

.App-header {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(160deg, rgb(68, 46, 120), rgb(55, 36, 99));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 1rem;
}

.App-link {
  color: #61dafb;
}

.App-social {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 20px auto;
  list-style: none;
}

.App-social li {
  margin: 0 1rem;
}

.App-social li a {
  display: block;
  color: #fff;
  height: 40px;
  width: 40px;
  line-height: 30px;
  border: 1px solid #fff;
  border-radius: 100%;
  transition: all 0.3s ease;
}

.App-social li a:hover {
  background-color: #fff;
  color: rgb(55, 36, 99);
}
